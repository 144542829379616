import React, { useState, useEffect, useRef, createRef } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';


function SurveyConsent() {
    const [selectedOption, setSelectedOption] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const PROLIFIC_PID = location.state?.PROLIFIC_PID;
    const STUDY_ID = location.state?.STUDY_ID;
    const SESSION_ID = location.state?.SESSION_ID;

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        setErrorMessage('');
    };

    const handleSubmit = () => {
        if (selectedOption === '') {
            setErrorMessage('Please choose an option.');
        } else if (selectedOption === 'agree') {
            navigate('/survey', { state: { PROLIFIC_PID: PROLIFIC_PID, STUDY_ID: STUDY_ID, SESSION_ID: SESSION_ID } });
        } else if (selectedOption === 'disagree') {
            setErrorMessage('You selected "I do not agree."');
        }
    };

    return (

        <div className='SurveyConsent'>

            <div className="container text-center">
                <br />
                {/*Headline:*/}
                <div className='survey_consent_headline'>
                    <h2>Informed Consent of Participation</h2>
                </div>
                <br />

                {/*Intro text:*/}
                <div className='survey_consent_section_1'>
                    You are invited to participate in the online study <b>Workshop Item Use and Perception </b>
                    conducted by Johanna Prinz, Carl Oechsner, Jan Leusmann, and Prof. Dr. Sven Mayer at LMU Munich.
                    <br />
                    <br />
                    Please note: Your participation is entirely voluntary.
                    The online study will last approximately <b>10-15 min</b>.
                    We will record personal demographics (age, gender, etc.).
                    We may publish the results from this survey in our reports, but all such reports will be anonymous
                    and will neither include your name nor can they be associated with your identity.
                    <br />
                    <br />
                    If you have any questions or complaints about the informed consent process of this research study
                    or your rights as a human research subject, please contact Johanna Prinz, Carl Oechsner, Jan Leusmann or Prof. Dr. Sven Mayer.
                    <br />
                    <br />
                    Please read the information below carefully. Please take as much time as you need to read the consent form.
                    <br />
                    <br />
                </div>


                {/*Ordered list of information:*/}
                <div className="container text-center" style={{ backgroundColor: '#f2f2f2' }}>
                    <b>1. Purpose and Goal of this Research</b>
                    <br />
                    Understanding the perceived danger level of various workshop items.
                    Your participation will help us achieve this goal. The results of this research may be presented at scientific
                    or professional meetings or published in scientific proceedings and journals.
                    <br />
                    <br />

                    <b>2. Participation and Compensation</b>
                    <br />
                    Your participation in this online study is completely voluntary.
                    You will receive an equivalent of 10EUR per hour compensation.
                    You may withdraw and discontinue participation at any time without penalty.
                    There will be no consequences if you decline to participate or withdraw from the survey.
                    You may refuse to answer any questions you do not want to answer.
                    <br />
                    <br />

                    <b>3. Procedure</b>
                    <br />
                    After confirming your informed consent, you will:
                    1. Fill in demographics
                    2. Fill in the Affinity for Technology Interaction (ATI) Scale
                    3. Look at ten workshop items and answer questions regarding your knowledge and perception of these items.
                    The complete procedure of this online study will last approximately 10-15 min.
                    <br />
                    <br />

                    <b>4. Risks and Benefits</b>
                    <br />
                    There are no risks associated with this online study.
                    Discomforts or inconveniences will be minor and are not likely to happen.
                    If any discomforts become a problem, you may discontinue your participation.
                    You will not directly benefit through participation in this online study.
                    We hope that the information obtained from your participation may help to bring forward the research in this field.
                    <br />
                    <br />

                    <b>5. Data Protection and Confidentiality</b>
                    <br />
                    Personal data (age, gender, etc.) will be recorded while participating.
                    The researcher will not identify you by your real name in any reports using information obtained from this online study.
                    Your confidentiality as a participant in this online study will remain secure and encrypted.
                    All data you provide in this online study will be published anonymized and treated confidentially in compliance with
                    the General Data Protection Regulation (GDPR) of the European Union (EU).
                    Subsequent uses of records and data will be subject to standard data use policies
                    which protect the full anonymity of the participating individuals.
                    In all cases, the uses of records and data will be subject to the GDPR.
                    Faculty and administrators from the campus will not have access to raw data or transcripts.
                    This precaution will prevent your individual comments from having any negative repercussions.
                    This site uses cookies and other tracking technologies to conduct the research, to improve the user experience,
                    the ability to interact with the system and to provide additional content from third parties.
                    Despite careful control of content, the researchers assume no liability for damages,
                    which directly or indirectly result from the use of this online application.
                    Any recordings cannot be viewed by anyone outside this research project
                    unless we have you sign a separate permission form allowing us to use them (see below).
                    Records that have not been made public are automatically deleted after the end of the research.
                    As with any publication or online-related activity, the risk of a breach of confidentiality is always possible.
                    According to the GDPR, the researchers will inform the participant if a breach of confidential data was detected.
                    <br />
                    <br />

                    <b>6. Identification of Investigators</b>
                    <br />
                    If you have any questions or concerns about the research, please feel free to contact:
                    <br />
                    <br />

                    Johanna Prinz<br />
                    Human-Computer Interaction, LMU Munich<br />
                    Frauenlobstr 7a 80337 Munich, Germany<br />
                    j.prinz@campus.lmu.de
                    <br />
                    <br />


                    Carl Oechsner<br />
                    Human-Computer Interaction, LMU Munich<br />
                    Frauenlobstr 7a 80337 Munich, Germany<br />
                    carl.oechsner@ifi.lmu.de
                    <br />
                    <br />


                    Jan Leusmann<br />
                    Human-Computer Interaction, LMU Munich<br />
                    Frauenlobstr 7a 80337 Munich, Germany<br />
                    jan.leusmann@ifi.lmu.de
                    <br />
                    <br />


                    Prof. Dr. Sven Mayer<br />
                    Human-Computer Interaction, LMU Munich<br />
                    Frauenlobstr 7a 80337 Munich, Germany<br />
                    sven.mayer@ifi.lmu.de
                    <br />
                    <br />
                </div>



                <div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                value="agree"
                                checked={selectedOption === 'agree'}
                                onChange={handleOptionChange}
                            />
                            I agree.
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="radio"
                                value="disagree"
                                checked={selectedOption === 'disagree'}
                                onChange={handleOptionChange}
                            />
                            I do not agree.
                        </label>
                    </div>
                    <button onClick={handleSubmit}>Submit</button>
                    {errorMessage && <p>{errorMessage}</p>}
                </div>
                <br />
                <br />
            </div>

        </div>
    );
}
export default SurveyConsent;