//steps:
//the survey has multiple steps
//each step for one page of questions/solo informtion
//the survey has 3 blocks: self, other, robot
//each block has ten steps (only includes steps with questions)

//formData and testData:
//formData carries all the data of the participants answers
//it is only used internally
//testData will also be put together step by step but is additionally 
//in the schema of the data base and will be sent to the db after completing the survey. 


import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import countries from './countries.js';
import affinityQuestions from './affinityQuestions.js'

import ItemSelfUsingComponent from './ItemSelfUsingComponent.js'
import ItemOthersUsingComponent from './ItemOthersUsingComponent.js'
import ItemRobotUsingComponent from './ItemRobotUsingComponent.js'
import AttentionCheck from './AttentionCheck.js'


const ActualSurvey = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const PROLIFIC_PID = location.state?.PROLIFIC_PID;
    const STUDY_ID = location.state?.STUDY_ID;
    const SESSION_ID = location.state?.SESSION_ID;

    const initialFormData = {
        PROLIFIC_PID: PROLIFIC_PID,
        STUDY_ID: STUDY_ID,
        SESSION_ID: SESSION_ID,
        demographics: {},
        workshopHabits: {},
        affinityTechnology: [],
        itemSelfUsing: [],
        itemOthersUsing: [],
        itemRobotUsing: [],
        attentionChecks: Array(3).fill(''),
        generalFeedback: {}
    };

    const [step, setStep] = useState(1);
    const [previousStep, setPreviousStep] = useState(0);
    const [formData, setFormData] = useState(initialFormData);

    const [attentionCheckIndexGlobal, setAttentionCheckIndexGlobal] = useState(-1);
    const [attentionCheckDirectionGlobal, setattentionCheckDirectionGlobal] = useState(-1); // -1 not defined, 0 right, 1 left

    //AC = Attention Check
    const [stepBeforeFirstAC] = useState(Math.floor(Math.random() * (13 - 5 + 1) + 5))
    const [stepBeforeSecondAC] = useState(Math.floor(Math.random() * (24 - 16 + 1) + 16))
    const [stepBeforeThirdAC] = useState(Math.floor(Math.random() * (36 - 28 + 1) + 28))

    //TODO rename
    const [testData, setTestData] = useState({})

    useEffect(() => {
        setTestData((prevTestData) => {
            return {
                ...prevTestData,
                timestampStart: new Date().toISOString(),
                dateAndTimezone: new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).format(new Date())
            }
        });
    }, []);

    //this error will be shown right before the "next" button, whenever clicking next with unvalid inputs
    const [error, setError] = useState('');

    //these errors are shown right before input fields that require numbers as input 
    //when a number out of bound was typed in
    const [numberError, setNumberError] = useState('')
    const [numberError2, setNumberError2] = useState('')
    const [numberError3, setNumberError3] = useState('')
    const [numberError4, setNumberError4] = useState('')

    //an array of the 10 workhop items which participant is being asked about
    const [workshopItems, setWorkshopItems] = useState([]);

    useEffect(() => {
        axios.get(`/api/surveyItems`)
            .then(response => {
                setWorkshopItems(response.data);

                //preload images
                response.data.forEach(item => {
                    const img = new Image();
                    img.src = `${process.env.PUBLIC_URL}/workshopItems/${item.File}`;
                });
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);


    const ensureInteger = (value) => Number.isInteger(Number(value));

    //several change handler methods:
    const handleChangeDemographics = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'radio') {
            setFormData((prevData) => ({
                ...prevData,
                demographics: {
                    ...prevData.demographics,
                    [name]: checked ? value : '',
                },
            }));
            return;
        }
        if (name === "age" && !ensureInteger(value)) {
            return;
        }
        if (name === 'age') {
            const ageValue = Number(value);
            if (ageValue < 18 || ageValue > 99) {
                setNumberError('Age must be a natural number between 18 and 99 (both included).');
            } else {
                setNumberError('');
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            demographics: {
                ...prevData.demographics,
                [name]: value,
            },
        }));
    };


    const handleChangeWorkshopHabits = (event) => {
        const { name, value } = event.target;

        if (name !== 'workshopExpert') {
            const intValue = Number(value);
            if (name === 'householdSize' && !ensureInteger(value)) {
                return;
            }
            if (name === 'householdSize') {
                if (intValue < 1) {
                    setNumberError('Please provide a number above 0');
                } else {
                    setNumberError('');
                }
            }
            if (name === 'totalWorkshopFrequency' || name === 'aloneWorkshopFrequency' || name === 'workshopWithOthersFrequency') {
                if (intValue < 0 || intValue > 112) {
                    const errorSetter = name === 'householdSize' ? setNumberError : name === 'totalWorkshopFrequency' ? setNumberError2 : name === 'aloneWorkshopFrequency' ? setNumberError3 : setNumberError4;
                    errorSetter('Please provide a number between 0 and 112');
                } else {
                    const errorSetter = name === 'householdSize' ? setNumberError : name === 'totalWorkshopFrequency' ? setNumberError2 : name === 'aloneWorkshopFrequency' ? setNumberError3 : setNumberError4;
                    errorSetter('');
                }
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            workshopHabits: {
                ...prevData.workshopHabits,
                [name]: value,
            },
        }));
    };



    const handleChangeAffinityTechnology = (event) => {
        const { name, value } = event.target;
        const questionIndex = parseInt(name.slice(-1)); // Extract the question index from the name
        setFormData((prevData) => {
            const updatedAffinityTechnology = [...prevData.affinityTechnology];
            updatedAffinityTechnology[questionIndex] = value;
            return {
                ...prevData,
                affinityTechnology: updatedAffinityTechnology,
            };
        });
    };

    const handleChangeItemSelfUsing = (name, value, selfStep) => {
        //console.log(name, value, selfStep);

        // Update the formData with the new values
        setFormData((prevFormData) => {
            const updatedItemSelfUsing = [...prevFormData.itemSelfUsing];
            const arrayIndex = selfStep - 1; // Adjust selfStep to match the array index

            const updatedStepData = {
                ...updatedItemSelfUsing[arrayIndex],
                [name]: value,
            };

            updatedItemSelfUsing[arrayIndex] = updatedStepData;
            //console.log(formData)

            return {
                ...prevFormData,
                itemSelfUsing: updatedItemSelfUsing,
            };
        });
    };

    const handleChangeItemOthersUsing = (name, value, othersStep) => {
        //console.log(name, value, othersStep);

        // Update the formData with the new values
        setFormData((prevFormData) => {
            const updatedItemOthersUsing = [...prevFormData.itemOthersUsing];
            const arrayIndex = othersStep - 1; // Adjust othersStep to match the array index

            const updatedStepData = {
                ...updatedItemOthersUsing[arrayIndex],
                [name]: value,
            };

            updatedItemOthersUsing[arrayIndex] = updatedStepData;
            //console.log(formData)

            return {
                ...prevFormData,
                itemOthersUsing: updatedItemOthersUsing,
            };
        });
    };

    const handleChangeItemRobotUsing = (name, value, robotStep) => {
        //console.log(name, value, robotStep);

        // Update the formData with the new values
        setFormData((prevFormData) => {
            const updatedItemRobotUsing = [...prevFormData.itemRobotUsing];
            const arrayIndex = robotStep - 1; // Adjust othersStep to match the array index

            const updatedStepData = {
                ...updatedItemRobotUsing[arrayIndex],
                [name]: value,
            };

            updatedItemRobotUsing[arrayIndex] = updatedStepData;
            //console.log(formData)

            return {
                ...prevFormData,
                itemRobotUsing: updatedItemRobotUsing,
            };
        });
    };

    const handleChangeFeedback = (event) => {
        const { value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            generalFeedback: value,
        }));
    };

    const handleChangeAttentionCheck = (value, attentionCheckIndex) => {
        setFormData((prevFormData) => {
            const updatedattentionChecks = [...prevFormData.attentionChecks];
            updatedattentionChecks[attentionCheckIndex] = value;

            return {
                ...prevFormData,
                attentionChecks: updatedattentionChecks,
            };
        });
    }


    const validateStep = () => {
        const stepErrors = {};
        const stepInputValues = {};
        if (step === 0) {
            //put validation for correct sliding here
        }
        //DEMOGRAPHICS:
        if (step === 1) {
            const { age, gender, highestDegree, primaryOccupation, residenceCountry } = formData.demographics;
            if (age === undefined || age < 18 || age > 99) {
                stepErrors.age = 'Age must be a natural number between 18 and 99 (both included).';
                console.log('no value for age')
            }
            if (gender === undefined) {
                stepErrors.gender = 'Please select a gender.';
                console.log('no value for gender')
            } else if (gender === 'prefer to self-describe' && (/^\s*$/.test(formData.demographics.selfDescription) || formData.demographics.selfDescription === undefined)) {
                stepErrors.selfDescription = 'Please provide your own description.';
                console.log('no value for own gender description')
            }
            if (highestDegree === undefined) {
                stepErrors.highestDegree = 'Please select the highest degree you have received.';
                console.log('no value for highest degree')
            }
            if (/^\s*$/.test(primaryOccupation) || primaryOccupation === undefined) {
                stepErrors.primaryOccupation = 'Please provide your current primary occupation.';
                console.log('no value for primary occupation')
            }
            if (residenceCountry === undefined) {
                stepErrors.residenceCountry = 'Please select your current residence country.';
                console.log('no value for residence country')
            }
        }

        //WORKSHOP HABITS AND EXPERTISE
        if (step === 2) {
            const { workshopExpert, householdSize, totalWorkshopFrequency, aloneWorkshopFrequency, workshopWithOthersFrequency } = formData.workshopHabits;

            if (workshopExpert === undefined) {
                stepErrors.workshopExpert = "Please provide your workshop expertise."
                console.log('no value for workshop expertise')
            }
            if (householdSize === undefined || householdSize < 1) {
                stepErrors.householdSize = "Please provide a valid number of people living in your household (including you)."
                console.log('no value for household size')
            }
            if (totalWorkshopFrequency === undefined || totalWorkshopFrequency < 0 || totalWorkshopFrequency > 112 || totalWorkshopFrequency == '') {
                stepErrors.totalWorkshopFrequency = "Please provide a valid average total workshop time in hours per week."
                console.log('no value for total workshop time in hours')
            }
            if (aloneWorkshopFrequency === undefined || aloneWorkshopFrequency < 0 || aloneWorkshopFrequency > 112 || aloneWorkshopFrequency == '') {
                stepErrors.aloneWorkshopFrequency = "Please provide a valid average alone workshop time in hours per week."
                console.log('no value for alone workshop time in hours')
            }
            if (workshopWithOthersFrequency === undefined || workshopWithOthersFrequency < 0 || workshopWithOthersFrequency > 112 || workshopWithOthersFrequency == '') {
                stepErrors.workshopWithOthersFrequency = "Please provide a valid average workshop time with others in hours per week."
                console.log('no value for workshop time with others')
            }
        }

        //AFFINITY FOR TECHNOLOGY INTERACTION
        if (step === 3) {
            const affinityTechnology = formData.affinityTechnology;
            for (let i = 0; i < affinityQuestions.length; i++) {
                if (affinityTechnology[i] === undefined) {
                    stepErrors.affinityTechnology = 'Please check at least one of the boxes for each question.'
                    console.log('no value for at least one statement')
                }
                //console.log(affinityTechnology[i])
            }
        }

        //WORKSHOP TOOL FAMILIARITY AND PERCEPTION WHEN USING THE ITEM
        if (step > 4 && step < 15) {
            // Get the formData for the current step
            const stepFormData = formData.itemSelfUsing[step - 5];


            // Check if any radio input is checked (using explicit check for undefined)
            if (stepFormData?.ownsItem === undefined) {
                stepErrors.ownsItem = 'Please select "Yes" or "No".';
                //console.log(stepFormData === undefined)
            }

            // Check if both sliders have values
            const familiarityValue = stepFormData?.familiarity;
            const dangerPerceptionValue = stepFormData?.dangerPerception;
            if (
                familiarityValue === undefined || // Change this condition to check for undefined only
                dangerPerceptionValue === undefined ||
                dangerPerceptionValue === ''
            ) {
                stepErrors.sliders = 'Please slide both sliders to provide familiarity and danger perception values.';
                console.log('no value for at least one slider');
            }

            //console.log("stepFormData: ", stepFormData);
            stepInputValues[step] = {
                ownsItem: stepFormData?.ownsItem,
                familiarity: familiarityValue,
                dangerPerception: dangerPerceptionValue,
            };
            //console.log("stepInputValues: ", stepInputValues[step]);
        }
        //DANGER PERCEPTION WHEN OTHERS USE THE ITEM
        if (step > 15 && step < 26) {
            const stepFormData = formData.itemOthersUsing[step - 16];
            const dangerPerceptionValue = stepFormData?.dangerPerception;
            if (
                dangerPerceptionValue === undefined ||
                dangerPerceptionValue === ''
            ) {
                stepErrors.sliders = 'Please slide slider to provide danger perception value.';
                console.log(stepErrors.sliders);
            }
            //console.log("stepFormData: ", stepFormData);
            stepInputValues[step] = {
                dangerPerception: dangerPerceptionValue,
            };
            //console.log("stepInputValues: ", stepInputValues[step]);

        }

        //DANGER PERCEPTION WHEN ROBOT IS USING THE ITEM
        if (step > 27 && step < 38) {
            const stepFormData = formData.itemRobotUsing[step - 28];
            const dangerPerceptionValue = stepFormData?.dangerPerception;
            if (
                dangerPerceptionValue === undefined ||
                dangerPerceptionValue === ''
            ) {
                stepErrors.sliders = 'Please slide slider to provide danger perception value.';
                console.log(stepErrors.sliders);
            }
            //console.log("stepFormData: ", stepFormData);
            stepInputValues[step] = {
                dangerPerception: dangerPerceptionValue,
            };
            //console.log("stepInputValues: ", stepInputValues[step]);

        }

        return stepErrors;
    };

    function renderErrorValues(stepErrors) {
        return Object.values(stepErrors).map((errorMsg, index) => <div key={index}>{errorMsg}</div>);
    }

    const nextStep = () => {
        //stepErrors always empty at the beginning of validateStep()
        const stepErrors = validateStep();
        //console.log('stepErrors length: ', Object.keys(stepErrors).length)
        if (Object.keys(stepErrors).length === 0) {
            //step 0 is the attention check step and will appear several times per survey;
            //after completing the attention check successfully and by remembering the number x of 
            //the step before step 0 (stored in previousStep)
            //previousStep will then have value of x and step will be x+1
            if (step === 0) {
                setStep((step) => previousStep + 1);
                setPreviousStep(0);
                //empty error when next step is successful
                setError('');

                setTestData((prevTestData) => {
                    const attentionCheckKey = `attentionCheck${attentionCheckIndexGlobal}`;

                    // last timestamp key based on the current attentionCheckIndexGlobal
                    let lastTimestampKey;
                    switch (attentionCheckIndexGlobal) {
                        case 0:
                            lastTimestampKey = `timestampSelfStep${stepBeforeFirstAC - 4}`;
                            break;
                        case 1:
                            lastTimestampKey = `timestampOthersStep${stepBeforeSecondAC - 15}`;
                            break;
                        case 2:
                            lastTimestampKey = `timestampRobotStep${stepBeforeThirdAC - 27}`;
                            break;
                        default:
                            lastTimestampKey = null;
                            console.log("last timestamp key based on the current attentionCheckIndexGlobal could not be found!");
                    }
                    const lastTimestamp = new Date(prevTestData[lastTimestampKey]);

                    const currentTimestamp = new Date();
                    const duration = (currentTimestamp - lastTimestamp) / 1000;

                    const currentTimestampKey = `timestampAC${attentionCheckIndexGlobal}`;
                    const durationKey = `durationAC${attentionCheckIndexGlobal}`;

                    return {
                        ...prevTestData,
                        [attentionCheckKey]: formData.attentionChecks[attentionCheckIndexGlobal],
                        [currentTimestampKey]: currentTimestamp.toISOString(),
                        [durationKey]: duration
                    };
                });

                // Scroll up
                window.scrollTo(0, 0);
                return;
            }

            if (step === 1) {//Demographics completed
                setTestData((prevTestData) => {
                    const startTime = new Date(prevTestData.timestampStart);
                    const timestampDemographics = new Date();
                    const durationDemographics = (timestampDemographics - startTime) / 1000;

                    const updatedData = {
                        ...prevTestData,
                        PROLIFIC_PID: PROLIFIC_PID,
                        STUDY_ID: STUDY_ID,
                        SESSION_ID: SESSION_ID,
                        item1: `workshopItems/${workshopItems[1].File}`,
                        item2: `workshopItems/${workshopItems[2].File}`,
                        item3: `workshopItems/${workshopItems[3].File}`,
                        item4: `workshopItems/${workshopItems[4].File}`,
                        item5: `workshopItems/${workshopItems[5].File}`,
                        item6: `workshopItems/${workshopItems[6].File}`,
                        item7: `workshopItems/${workshopItems[7].File}`,
                        item8: `workshopItems/${workshopItems[8].File}`,
                        item9: `workshopItems/${workshopItems[9].File}`,
                        item0: `workshopItems/${workshopItems[0].File}`,
                        itemName1: workshopItems[1].Values,
                        itemName2: workshopItems[2].Values,
                        itemName3: workshopItems[3].Values,
                        itemName4: workshopItems[4].Values,
                        itemName5: workshopItems[5].Values,
                        itemName6: workshopItems[6].Values,
                        itemName7: workshopItems[7].Values,
                        itemName8: workshopItems[8].Values,
                        itemName9: workshopItems[9].Values,
                        itemName0: workshopItems[0].Values,
                        item1ID: workshopItems[1]._id,
                        item2ID: workshopItems[2]._id,
                        item3ID: workshopItems[3]._id,
                        item4ID: workshopItems[4]._id,
                        item5ID: workshopItems[5]._id,
                        item6ID: workshopItems[6]._id,
                        item7ID: workshopItems[7]._id,
                        item8ID: workshopItems[8]._id,
                        item9ID: workshopItems[9]._id,
                        item0ID: workshopItems[0]._id,
                        AgeS: Number(formData.demographics.age),
                        SexS: formData.demographics.gender,
                        degree: formData.demographics.highestDegree,
                        occupation: formData.demographics.primaryOccupation,
                        SelfDescription: formData.demographics.selfDescription !== undefined ? formData.demographics.selfDescription : 'no description',
                        country: formData.demographics.residenceCountry,
                        timestampDemographics: timestampDemographics.toISOString(),
                        durationDemographics: durationDemographics
                    };
                    //console.log("DEBUG: Updated testData:", updatedData);
                    return updatedData;
                }
                )
            }
            if (step === 2) {//workshop habits and expertise completed
                setTestData((prevTestData) => {
                    const timestampDemographics = new Date(prevTestData.timestampDemographics);
                    const timestampWorkshopHabits = new Date();
                    const durationWorkshopHabits = (timestampWorkshopHabits - timestampDemographics) / 1000;

                    return {
                        ...prevTestData,
                        workshop_expertise: Number(formData.workshopHabits.workshopExpert),
                        household: Number(formData.workshopHabits.householdSize),
                        workshop_frequency: Number(formData.workshopHabits.totalWorkshopFrequency),
                        workshop_alone: Number(formData.workshopHabits.aloneWorkshopFrequency),
                        workshop_together: Number(formData.workshopHabits.workshopWithOthersFrequency),
                        timestampWorkshopHabits: timestampWorkshopHabits.toISOString(),
                        durationWorkshopHabits: durationWorkshopHabits
                    }
                }
                )
            }
            if (step === 3) {//affinity technology completed
                setTestData((prevTestData) => {
                    const timestampWorkshopHabits = new Date(prevTestData.timestampWorkshopHabits);
                    const timestampAffinityTechnology = new Date();
                    const durationAffinityTechnology = (timestampAffinityTechnology - timestampWorkshopHabits) / 1000;

                    return {
                        ...prevTestData,
                        ATI01: formData.affinityTechnology[0],
                        ATI02: formData.affinityTechnology[1],
                        ATI03: formData.affinityTechnology[2],
                        ATI04: formData.affinityTechnology[3],
                        ATI05: formData.affinityTechnology[4],
                        ATI06: formData.affinityTechnology[5],
                        ATI07: formData.affinityTechnology[6],
                        ATI08: formData.affinityTechnology[7],
                        ATI09: formData.affinityTechnology[8],
                        timestampAffinityTechnology: timestampAffinityTechnology.toISOString(),
                        durationAffinityTechnology: durationAffinityTechnology
                    }
                }
                )
            }
            if (step === 4) { //Instruction1
                setTestData((prevTestData) => {
                    const timestampAffinityTechnology = new Date(prevTestData.timestampAffinityTechnology);
                    const timestampInstruction1 = new Date();
                    const durationInstruction1 = (timestampInstruction1 - timestampAffinityTechnology) / 1000;

                    return {
                        ...prevTestData,
                        timestampInstruction1: timestampInstruction1.toISOString(),
                        durationInstruction1: durationInstruction1
                    }
                })
            }
            if (step > 4 && step < 15) { //self block -> {5,6,..,14}
                setAttentionCheckIndexGlobal(0);
                setattentionCheckDirectionGlobal(0); //right
                setTestData((prevTestData) => {
                    // since this block starts from 5: 5-4 = 1 ---> self step starts from 1, goes up to 10
                    const selfStep = step - 4;
                    const propertyName1 = `${selfStep}_using_self_owning`;
                    const propertyName2 = `${selfStep}_using_self_familiar`;
                    const propertyName3 = `${selfStep}_using_self`;

                    // last timestamp
                    const lastTimestampKey = selfStep === 1 ? 'timestampInstruction1' : `timestampSelfStep${selfStep - 1}`;
                    const lastTimestamp = new Date(prevTestData[lastTimestampKey]);

                    // currentTimestamp and duration
                    const currentTimestamp = new Date();
                    const duration = (currentTimestamp - lastTimestamp) / 1000;

                    // dynamic keys
                    const currentTimestampKey = `timestampSelfStep${selfStep}`;
                    const durationKey = `durationSelfStep${selfStep}`;

                    return {
                        ...prevTestData,
                        [propertyName1]: formData.itemSelfUsing[selfStep - 1].ownsItem,
                        [propertyName2]: Number(formData.itemSelfUsing[selfStep - 1].familiarity),
                        [propertyName3]: Number(formData.itemSelfUsing[selfStep - 1].dangerPerception),
                        [currentTimestampKey]: currentTimestamp.toISOString(),
                        [durationKey]: duration
                    };
                });
                //step before AC
                if (step === stepBeforeFirstAC) {
                    setStep(0)
                    setPreviousStep((prevStep) => prevStep + 1)
                    //empty error when next step is successful
                    setError('')
                    // Scroll up
                    window.scrollTo(0, 0);
                    return
                }
                //console.log(testData)
            }
            if (step === 15) { //Instruction2
                setTestData((prevTestData) => {
                    const timestampStep14 = new Date(prevTestData.timestampSelfStep10);
                    const timestampInstruction2 = new Date();
                    const durationInstruction2 = (timestampInstruction2 - timestampStep14) / 1000;

                    return {
                        ...prevTestData,
                        timestampInstruction2: timestampInstruction2.toISOString(),
                        durationInstruction2: durationInstruction2
                    }
                });
            }
            if (step > 15 && step < 26) {//others block -> {16,17,..,25}
                setAttentionCheckIndexGlobal(1)
                setattentionCheckDirectionGlobal(1) //left
                setTestData((prevTestData) => {
                    //since this block starts from step 16 -> 16-15 = 1 ---> see above
                    const othersStep = step - 15;
                    const propertyName = `${othersStep}_using_other`;

                    // last timestamp
                    const lastTimestampKey = othersStep === 1 ? 'timestampInstruction2' : `timestampOthersStep${othersStep - 1}`;
                    const lastTimestamp = new Date(prevTestData[lastTimestampKey]);

                    // currentTimestamp and duration
                    const currentTimestamp = new Date();
                    const duration = (currentTimestamp - lastTimestamp) / 1000;

                    // dynamic keys
                    const currentTimestampKey = `timestampOthersStep${othersStep}`;
                    const durationKey = `durationOthersStep${othersStep}`;

                    return {
                        ...prevTestData,
                        [propertyName]: Number(formData.itemOthersUsing[othersStep - 1].dangerPerception),
                        [currentTimestampKey]: currentTimestamp.toISOString(),
                        [durationKey]: duration
                    };
                });
                //step before AC
                if (step === stepBeforeSecondAC) {
                    setStep(0)
                    setPreviousStep((prevStep) => prevStep + 1)
                    //empty error when next step is successful
                    setError('')
                    // Scroll up
                    window.scrollTo(0, 0);
                    return
                }
            }

            if (step === 26) {//Instruction3
                setTestData((prevTestData) => {
                    const timestampOthersStep10 = new Date(prevTestData.timestampOthersStep10);
                    const timestampInstruction3 = new Date();
                    const durationInstruction3 = (timestampInstruction3 - timestampOthersStep10) / 1000;

                    return {
                        ...prevTestData,
                        timestampInstruction3: timestampInstruction3.toISOString(),
                        durationInstruction3: durationInstruction3
                    }
                });
            }

            if (step === 27) {//Instruction4
                setTestData((prevTestData) => {
                    const timestampInstruction3 = new Date(prevTestData.timestampInstruction3);
                    const timestampInstruction4 = new Date();
                    const durationInstruction4 = (timestampInstruction4 - timestampInstruction3) / 1000;

                    return {
                        ...prevTestData,
                        timestampInstruction4: timestampInstruction4.toISOString(),
                        durationInstruction4: durationInstruction4
                    }
                });
            }

            if (step > 27 && step < 38) {//robot block -> {28,29,..,37}
                setAttentionCheckIndexGlobal(2)
                setattentionCheckDirectionGlobal(0) //right
                setTestData((prevTestData) => {
                    // since this block starts from step 28 -> 28-27 = 1 ---> robot step starts from 1
                    const robotStep = step - 27;
                    const propertyName = `${robotStep}_using_robot`;

                    // last timestamp
                    const lastTimestampKey = robotStep === 1 ? 'timestampInstruction4' : `timestampRobotStep${robotStep - 1}`;
                    const lastTimestamp = new Date(prevTestData[lastTimestampKey]);

                    // currentTimestamp and duration
                    const currentTimestamp = new Date();
                    const duration = (currentTimestamp - lastTimestamp) / 1000;

                    // dynamic keys
                    const currentTimestampKey = `timestampRobotStep${robotStep}`;
                    const durationKey = `durationRobotStep${robotStep}`;

                    return {
                        ...prevTestData,
                        [propertyName]: Number(formData.itemRobotUsing[robotStep - 1].dangerPerception),
                        [currentTimestampKey]: currentTimestamp.toISOString(),
                        [durationKey]: duration
                    };
                });
                //step before AC
                if (step === stepBeforeThirdAC) {
                    setStep(0)
                    setPreviousStep((prevStep) => prevStep + 1)
                    //empty error when next step is successful
                    setError('')
                    // Scroll up
                    window.scrollTo(0, 0);
                    return
                }
            }
            if (step === 38) {
                setTestData((prevTestData) => {
                    const timestampStart = new Date(prevTestData.timestampStart);
                    const timestepFeedback = new Date();
                    const durationTotal = (timestepFeedback - timestampStart) / 1000;

                    const timestampRobotStep10 = new Date(prevTestData.timestampRobotStep10);
                    const durationStep38 = (timestepFeedback - timestampRobotStep10) / 1000;

                    return {
                        ...prevTestData,
                        general_feedback: formData.generalFeedback,
                        timestepFeedback: timestepFeedback.toISOString(),
                        durationTotal: durationTotal,
                        durationFeedback: durationStep38
                    };
                });
            }

            //console.log('next step successful')
            setStep((step) => step + 1);
            setPreviousStep((prevStep) => step);
            //empty error when next step is successful
            setError('')

            // Scroll up
            window.scrollTo(0, 0);
            //console.log("previous step: ", previousStep)
            //console.log("step: ", step)
        }
        else {
            if (step === 1) {
                setError(
                    <>
                        Please complete all the necessary fields:
                        <br />
                        {renderErrorValues(stepErrors)}
                    </>
                );
            } else if (step === 2 || (step > 4 && step < 15) || (step > 15 && step < 26) || (step > 27 && step < 38)) {
                setError(
                    <>
                        Please complete all the necessary fields (includes sliding the slider at least once):
                        <br />
                        {renderErrorValues(stepErrors)}
                    </>
                );
            } else if (step === 3) {
                setError(stepErrors.affinityTechnology);  // Adjust this if necessary
            }
        }
    };

    const goToPreviousStep = () => {
        const stepAfterFirstAC = stepBeforeFirstAC + 1
        const stepAfterSecondAC = stepBeforeSecondAC + 1
        const stepAfterThirdAC = stepBeforeThirdAC + 1
        setStep((step) => previousStep)

        // Clear error messages
        setError('');
        setNumberError('');
        setNumberError2('');
        setNumberError3('');
        setNumberError4('');

        if (previousStep === 0) {
            setPreviousStep((prevStep) => step - 1)

        }
        else if (previousStep === stepAfterFirstAC || previousStep === stepAfterSecondAC || previousStep === stepAfterThirdAC) {
            setPreviousStep(0)
        }
        else {
            setPreviousStep((prevStep) => prevStep - 1)
        }
    };

    const submitForm = (e) => {
        // Send formData to the backend and store in the database 

        e.preventDefault();

        axios
            .post(`/api/submitSurvey`, testData)
            .then((res) => {
                setFormData(initialFormData);

                // Reset testData
                setTestData({});

                // Push to /
                navigate('/thank-you');
            })
            .catch((err) => {
                console.log('Error in submitting survey data! ', err);
            });
        console.log('Submitted following survey data: ')
        //console.log(formData);
        console.log(testData)
    };

    const renderStep = () => {
        //object destructuring
        const { age, gender, selfDescription, highestDegree, primaryOccupation, residenceCountry } = formData.demographics;
        const { workshopExpert, householdSize, totalWorkshopFrequency, aloneWorkshopFrequency, workshopWithOthersFrequency } = formData.workshopHabits
        const affinityTechnology = formData.affinityTechnology;
        const { feedback } = formData.generalFeedback;
        switch (step) {
            case 0:
                return (
                    <Container>
                        <AttentionCheck
                            workshopItem={workshopItems[2]}
                            attentionCheckIndex={attentionCheckIndexGlobal}
                            attentionCheckDirection={attentionCheckDirectionGlobal}
                            onChange={handleChangeAttentionCheck}
                            onNextStep={nextStep}
                            onPrevStep={goToPreviousStep}
                            formData={formData}
                            error={error}
                        />
                    </Container>
                );
            case 1:
                return (
                    <Container>

                        <div>
                            <br />
                            <br />
                            <h2>Demographics</h2>
                            <br />
                            <br />

                            {numberError && <Alert variant="danger">{numberError}</Alert>}
                            <Form.Group>
                                <Form.Label>How old are you?</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="age"
                                    value={age || ''}
                                    onChange={handleChangeDemographics}
                                    min={18}
                                    max={99}
                                />
                            </Form.Group>
                            <br />

                            <Form.Group>
                                <Form.Label>How do you describe yourself?</Form.Label>

                                <Form.Check
                                    id="male"
                                    type="radio"
                                    name="gender"
                                    label="Male"
                                    value="male"
                                    checked={gender === 'male'}
                                    onChange={handleChangeDemographics}
                                />

                                <Form.Check
                                    id="female"
                                    type="radio"
                                    name="gender"
                                    label="Female"
                                    value="female"
                                    checked={gender === 'female'}
                                    onChange={handleChangeDemographics}
                                />

                                <Form.Check
                                    id="non-binary"
                                    type="radio"
                                    name="gender"
                                    label="Non-binary / Third gender"
                                    value="non-binary / third gender"
                                    checked={gender === 'non-binary / third gender'}
                                    onChange={handleChangeDemographics}
                                />

                                <Form.Check
                                    id="self-describe"
                                    type="radio"
                                    name="gender"
                                    label="Prefer to self-describe"
                                    value="prefer to self-describe"
                                    checked={gender === 'prefer to self-describe'}
                                    onChange={handleChangeDemographics}
                                />

                                {gender === 'prefer to self-describe' && (
                                    <Form.Control
                                        type="text"
                                        name="selfDescription"
                                        value={selfDescription || ""}
                                        onChange={handleChangeDemographics}
                                        placeholder="Please provide your own description"
                                    />
                                )}
                            </Form.Group>
                            <br />




                            <Form.Group>
                                <Form.Label>What is the highest degree you have received?</Form.Label>
                                <Form.Check
                                    type="radio"
                                    id="High school graduate"
                                    name="highestDegree"
                                    label="High school graduate"
                                    value="High school graduate"
                                    checked={highestDegree === 'High school graduate'}
                                    onChange={handleChangeDemographics}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Some college but no degree"
                                    name="highestDegree"
                                    label="Some college but no degree"
                                    value="Some college but no degree"
                                    checked={highestDegree === 'Some college but no degree'}
                                    onChange={handleChangeDemographics}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Bachelor's degree"
                                    name="highestDegree"
                                    label="Bachelor's degree"
                                    value="Bachelor's degree"
                                    checked={highestDegree === "Bachelor's degree"}
                                    onChange={handleChangeDemographics}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Master's degree"
                                    name="highestDegree"
                                    label="Master's degree"
                                    value="Master's degree"
                                    checked={highestDegree === "Master's degree"}
                                    onChange={handleChangeDemographics}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Doctoral degree"
                                    name="highestDegree"
                                    label="Doctoral degree"
                                    value="Doctoral degree"
                                    checked={highestDegree === 'Doctoral degree'}
                                    onChange={handleChangeDemographics}
                                />
                                <Form.Check
                                    type="radio"
                                    id="Vocational education"
                                    name="highestDegree"
                                    label="Vocational education"
                                    value="Vocational education"
                                    checked={highestDegree === 'Vocational education'}
                                    onChange={handleChangeDemographics}
                                />
                            </Form.Group>
                            <br />


                            <Form.Group>
                                <Form.Label>What is your current primary occupation?</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="primaryOccupation"
                                    value={primaryOccupation || ""}
                                    onChange={handleChangeDemographics}
                                />
                            </Form.Group>
                            <br />


                            <Form.Group>
                                <Form.Label>Which country do you currently reside in?</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="residenceCountry"
                                    value={residenceCountry || ""}
                                    onChange={handleChangeDemographics}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <br />


                            {error && <Alert variant="danger">{error}</Alert>}
                            <Button variant="primary" onClick={nextStep}>Next</Button>
                        </div>

                    </Container>
                );
            case 2:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Workshop Habits and Expertise</h2>
                            <br />
                            <br />

                            <Form.Group>
                                <Form.Label>I consider myself a workshop expert.</Form.Label>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ paddingRight: "10px" }}>Strongly Disagree</span>
                                    <Form.Control
                                        type="range"
                                        name="workshopExpert"
                                        min="0"
                                        max="100"
                                        value={workshopExpert || ""}
                                        onChange={handleChangeWorkshopHabits}
                                    />
                                    <span style={{ paddingLeft: "10px" }}>Strongly Agree</span>
                                </div>
                            </Form.Group>
                            <br />

                            {numberError && <Alert variant="danger">{numberError}</Alert>}
                            <Form.Group>
                                <Form.Label>How many people live in your household?</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="householdSize"
                                    min="1"
                                    max="99"
                                    value={householdSize || ""}
                                    onChange={handleChangeWorkshopHabits}
                                />
                            </Form.Group>
                            <br />

                            {numberError2 && <Alert variant="danger">{numberError2}</Alert>}
                            <Form.Group>
                                <Form.Label>
                                    On average, how many hours a week do you work in a workshop / with workshop tools <b>in total</b>?
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="totalWorkshopFrequency"
                                    min="0"
                                    max="112"
                                    value={totalWorkshopFrequency || ""}
                                    onChange={handleChangeWorkshopHabits}
                                />
                            </Form.Group>
                            <br />

                            {numberError3 && <Alert variant="danger">{numberError3}</Alert>}
                            <Form.Group>
                                <Form.Label>
                                    On average, how many hours a week do you work in a workshop / with workshop tools <b>alone</b>?
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="aloneWorkshopFrequency"
                                    min="0"
                                    max="112"
                                    value={aloneWorkshopFrequency || ""}
                                    onChange={handleChangeWorkshopHabits}
                                />
                            </Form.Group>
                            <br />

                            {numberError4 && <Alert variant="danger">{numberError4}</Alert>}
                            <Form.Group>
                                <Form.Label>
                                    On average, how many hours a week do you work in a workshop / with workshop tools <b>with others</b>?
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="workshopWithOthersFrequency"
                                    min="0"
                                    max="112"
                                    value={workshopWithOthersFrequency || ""}
                                    onChange={handleChangeWorkshopHabits}
                                />
                            </Form.Group>
                            <br />

                            {error && <Alert variant="danger">{error}</Alert>}

                            <Button variant="secondary" onClick={goToPreviousStep}>
                                Previous
                            </Button>
                            <Button variant="primary" onClick={nextStep}>
                                Next
                            </Button>

                        </div>
                    </Container>
                );
            case 3:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Affinity for Technology Interaction</h2>
                            <br />
                            <br />
                            <div>In the following questionnaire, we will ask you about your
                                interaction with technical systems. The term “technical
                                systems” refers to apps and other software applications,
                                as well as entire digital devices (e.g., mobile phone,
                                computer, TV, car navigation).<br />
                                Please indicate the degree to which you agree/disagree
                                with the following statements.</div>
                            <br />
                            <br />
                            {affinityQuestions.map((question, index) => (

                                <Form.Group key={index}>
                                    <div style={{ backgroundColor: '#f2f2f2' }}>
                                        <Form.Label>{question}</Form.Label>
                                        <div>
                                            <Form.Check
                                                type="radio"
                                                id={`Completely Disagree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Completely Disagree"
                                                value="Completely Disagree"
                                                checked={affinityTechnology[index] === 'Completely Disagree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`Largely Disagree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Largely Disagree"
                                                value="Largely Disagree"
                                                checked={affinityTechnology[index] === 'Largely Disagree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`Slightly Disagree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Slightly Disagree"
                                                value="Slightly Disagree"
                                                checked={affinityTechnology[index] === 'Slightly Disagree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`Slightly Agree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Slightly Agree"
                                                value="Slightly Agree"
                                                checked={affinityTechnology[index] === 'Slightly Agree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`Largely Agree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Largely Agree"
                                                value="Largely Agree"
                                                checked={affinityTechnology[index] === 'Largely Agree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                            <Form.Check
                                                type="radio"
                                                id={`Completely Agree-${index}`}
                                                name={`affinityQuestion${index}`}
                                                label="Completely Agree"
                                                value="Completely Agree"
                                                checked={affinityTechnology[index] === 'Completely Agree'}
                                                onChange={handleChangeAffinityTechnology}
                                                inline
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                </Form.Group>
                            ))}
                            <br />
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Button variant="secondary" onClick={goToPreviousStep}>
                                Previous
                            </Button>
                            <Button variant="primary" onClick={nextStep}>
                                Next
                            </Button>

                        </div>
                        <br />
                        <br />
                    </Container>
                );
            case 4:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Workshop Tool Familiarity and Perception when Using the Item</h2>
                            <br />
                            <br />
                            <div style={{ backgroundColor: '#f2f2f2' }}>
                                <p>In the following, you will be presented a workshop item and questions about how familiar you are with the item and your perception when using it <b>yourself</b>.</p>
                            </div>
                            <br />
                            <br />
                            {/* The "next" and "previous" buttons */}
                            <Button variant="secondary" onClick={goToPreviousStep}>Previous</Button>
                            <Button variant="primary" onClick={nextStep}>Next</Button>

                        </div>
                    </Container>
                );
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
                return (
                    <Container>
                        <ItemSelfUsingComponent
                            workshopItem={workshopItems[step - 5]}
                            onChange={handleChangeItemSelfUsing}
                            onNextStep={nextStep}
                            onPrevStep={goToPreviousStep}
                            formData={formData}
                            selfStep={step - 4}
                            error={error}
                        />
                    </Container>
                );
            case 15:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Workshop Tool Perception when Others Use the Item</h2>
                            <br />
                            <br />
                            <div style={{ backgroundColor: '#f2f2f2' }}>
                                <p>In the following you will be presented the same 10 workshop items with questions regarding your danger perception
                                    when <b>someone next to you</b> is using this item.
                                </p>
                            </div>
                            <br />
                            <br />
                            {/* The "next" and "previous" buttons */}
                            <Button variant="secondary" onClick={goToPreviousStep}>Previous</Button>
                            <Button variant="primary" onClick={nextStep}>Next</Button>

                        </div>
                    </Container>
                );
            case 16:
            case 17:
            case 18:
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            case 24:
            case 25:
                return (
                    <Container>
                        <ItemOthersUsingComponent
                            workshopItem={workshopItems[step - 16]}
                            onChange={handleChangeItemOthersUsing}
                            onNextStep={nextStep}
                            onPrevStep={goToPreviousStep}
                            formData={formData}
                            othersStep={step - 15}
                            error={error}
                        />
                    </Container>
                );
            case 26:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Workshop Robot Explanation</h2>
                            <br />
                            <br />
                            <div style={{ backgroundColor: '#f2f2f2' }}>
                                <p>Imagine yourself being in a workshop environment where robot arms are available to support you with all workshop
                                    tasks. These robot arms are able to grab and move every item you can find in a workshop.
                                </p>
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/cobot.jpg`} alt="Cobot" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                            <br />
                            <br />
                            {/* The "next" and "previous" buttons */}
                            <Button variant="secondary" onClick={goToPreviousStep}>Previous</Button>
                            <Button variant="primary" onClick={nextStep}>Next</Button>

                        </div>
                    </Container>
                );
            case 27:
                return (
                    <Container>
                        <div>
                            <br />
                            <br />
                            <h2>Workshop Tool Perception when Robot Uses the Item</h2>
                            <br />
                            <br />
                            <div style={{ backgroundColor: '#f2f2f2' }}>
                                <p>In the following you will be presented the same 10 workshop items with questions regarding your danger perception
                                    when such a <b>robot</b> next to you is using this item.
                                </p>
                            </div>
                            <br />
                            <br />
                            {/* The "next" and "previous" buttons */}
                            <Button variant="secondary" onClick={goToPreviousStep}>Previous</Button>
                            <Button variant="primary" onClick={nextStep}>Next</Button>

                        </div>
                    </Container>
                );

            case 28:
            case 29:
            case 30:
            case 31:
            case 32:
            case 33:
            case 34:
            case 35:
            case 36:
            case 37:
                return (
                    <Container>
                        <ItemRobotUsingComponent
                            workshopItem={workshopItems[step - 28]}
                            onChange={handleChangeItemRobotUsing}
                            onNextStep={nextStep}
                            onPrevStep={goToPreviousStep}
                            formData={formData}
                            robotStep={step - 27}
                            error={error}
                        />
                    </Container>
                );
            case 38:
                return (
                    <div>
                        <br />
                        <br />
                        <h2>General Feedback</h2>
                        <br />
                        <br />
                        <div style={{ backgroundColor: '#f2f2f2' }}>
                            <p>This is the end of the survey. If you have any further feedback, this is the last spot where you can let us know.
                            </p>
                        </div>
                        <br />
                        <br />
                        <Form.Group>
                            <Form.Label>Your feedback:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="feedback"
                                rows={4}
                                value={feedback}
                                onChange={handleChangeFeedback}
                            />
                        </Form.Group>
                        <br />



                        <Button variant="secondary" onClick={goToPreviousStep}>Previous</Button>
                        <Button variant="primary" onClick={nextStep}>Next</Button>
                        <br />
                        <br />
                    </div>
                );
            case 39:
                return (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="text-center">
                            <div className='submit_button'>
                                <a className="btn btn-primary" role="button" onClick={submitForm}>Submit</a>
                            </div>
                            <p className="text-muted mt-2">Press the button to submit the survey and wait to be directed to the next page.</p>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <Container>
            <Form>
                {renderStep()}
            </Form>
        </Container>
    );
};

export default ActualSurvey;
