import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import NavbarComponent from './NavbarComponent';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, Legend, BarElement, Title } from 'chart.js';

// Register the components with Chart.js
ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, BarElement, Title);


const options = {
    indexAxis: 'y', // Make the bars horizontal
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    maintainAspectRatio: false,  // Add this line
    plugins: {
        legend: {
            display: false, // Hide legend
        },
        title: {
            display: false, // Hide title
        },
    },
    scales: {
        x: {
            beginAtZero: true,
            position: 'top',  // Move the X-axis scale to the top
        },
        y: {
            minBarLength: 20,
            maxBarThickness: 30,
        },
    }
};

const outerContainerStyle = {
    padding: '0 10%', // Padding left and right
};


function Data() {
    const [workshopItems, setWorkshopItems] = useState([]);
    const [surveyResults, setSurveyResults] = useState([]);
    const [sortCriterion, setSortCriterion] = useState('Avg Robot');

    const [searchKeyword, setSearchKeyword] = useState('');

    const [sortOrder, setSortOrder] = useState('asc');  // 'asc' or 'desc'

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const workshopResponse = await axios.get(`/api/workshopItems`);
            setWorkshopItems(workshopResponse.data);

            const surveyResponse = await axios.get(`/api/dangerPerception`);
            setSurveyResults(surveyResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getSurveyResultForItem = (itemId) => {
        return surveyResults.find(result => result.itemID === itemId);
    };

    const sortObjects = (a, b) => {
        let result = 0;
        switch (sortCriterion) {
            case 'Name':
                result = a.Values.localeCompare(b.Values);
                break;
            case 'Avg Self':
            case 'Avg Other':
            case 'Avg Robot':
            case 'Frequency':
                const surveyA = getSurveyResultForItem(a._id);
                const surveyB = getSurveyResultForItem(b._id);
                const key = sortCriterion.split(' ').join('_').toLowerCase();
                result = (surveyB?.[key] || 0) - (surveyA?.[key] || 0);
                break;
            default:
                return 0;
        }

        return sortOrder === 'asc' ? result : -result;
    };


    const createAggregatedChartData = () => {
        const labels = [];
        const avgSelfData = [];
        const avgOtherData = [];
        const avgRobotData = [];

        workshopItems.filter(item => item.Values.toLowerCase().includes(searchKeyword.toLowerCase())).sort(sortObjects).forEach((object) => {
            const surveyResult = getSurveyResultForItem(object._id);
            if (surveyResult) {
                labels.push(object.Values);
                avgSelfData.push(surveyResult.avg_self);
                avgOtherData.push(surveyResult.avg_other);
                avgRobotData.push(surveyResult.avg_robot);
            }
        });

        return {
            labels,
            datasets: [
                {
                    label: 'Self',
                    data: avgSelfData,
                    backgroundColor: 'red',
                },
                {
                    label: 'Other',
                    data: avgOtherData,
                    backgroundColor: 'blue',
                },
                {
                    label: 'Robot',
                    data: avgRobotData,
                    backgroundColor: 'green',
                },
            ],
        };
    };

    const filteredItems = workshopItems.filter(item => item.Values.toLowerCase().includes(searchKeyword.toLowerCase()));

    const jsonToCSV = (jsonArray) => {
        const keys = Object.keys(jsonArray[0]);
        const lines = jsonArray.map(obj => keys.map(key => obj[key]).join(','));
        return `${keys.join(',')}\n${lines.join('\n')}`;
    };

    const downloadData = async () => {
        try {
            const response = await axios.get('/api/submissions');
            const csvData = jsonToCSV(response.data);
            const blob = new Blob([csvData], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'survey_data.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading data:', error);
        }
    };

    return (
        <div className='green-bg'>
            <NavbarComponent />
            <div className="Data" style={outerContainerStyle}>
                <Container fluid>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchKeyword}
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            style={{ marginRight: '10px' }} // Add right margin
                        />
                        <Dropdown onSelect={(key) => setSortCriterion(key)} style={{ marginRight: '10px' }}> {/* Add right margin */}
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Sort By
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="Name">Name</Dropdown.Item>
                                <Dropdown.Item eventKey="Avg Self">Self</Dropdown.Item>
                                <Dropdown.Item eventKey="Avg Other">Other</Dropdown.Item>
                                <Dropdown.Item eventKey="Avg Robot">Robot</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
                            {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                        </Button>
                        <Button onClick={downloadData} style={{ marginLeft: '10px' }}>
                            Download Data
                        </Button>
                    </div>

                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <p>Use the search bar to find specific items, sort them using the dropdown, and toggle the sort order with the button.</p>
                    </div>



                    <Row>
                        {/* Left-hand side: Overview */}
                        <Col md={4} style={{ backgroundColor: 'white', marginRight: '20px' }}>
                            <div style={{ width: '100%', height: `${50 * filteredItems.length}px` }}>
                                <Bar
                                    data={createAggregatedChartData()}
                                    options={options}
                                />
                            </div>
                        </Col>

                        {/* Right-hand side: Images and Info */}
                        <Col md={7} style={{ backgroundColor: 'white', display: 'flex', flexWrap: 'wrap' }}>
                            {workshopItems.filter(item => item.Values.toLowerCase().includes(searchKeyword.toLowerCase())).sort(sortObjects).map(object => {
                                const surveyResult = getSurveyResultForItem(object._id);
                                return (
                                    <div key={object._id} style={{ flex: '0 0 calc(33.333% - 10px)', margin: '5px' }}>  {/* Add flex and margin here */}
                                        <img
                                            src={`workshopItems/${object.File}`}
                                            alt={object.Comment}
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                        <div>
                                            <strong>Name: </strong>{object.Values}<br />
                                            {surveyResult && (
                                                <div>
                                                    <strong>Avg Self: </strong>{Math.round(surveyResult.avg_self)}<br />
                                                    <strong>Avg Other: </strong>{Math.round(surveyResult.avg_other)}<br />
                                                    <strong>Avg Robot: </strong>{Math.round(surveyResult.avg_robot)}<br />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Col>

                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Data;