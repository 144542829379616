import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import StartSurvey from './components/StartSurvey';
import SurveyConsent from './components/SurveyConsent';
import ActualSurvey from './components/ActualSurvey';
import Data from './components/Data';
import ScientificWork from './components/ScientificWork';
import Welcome from './components/Welcome';
import ShowItemDetails from './components/ShowItemDetails';
import Discussion from './components/Discussion';
import TestBackend from './components/TestBackend';
import TestSubmit from './components/TestSubmit';
import ThankYouPage from './components/ThankYouPage';
import TestPage from './components/TestPage';
import Placeholder from './components/Placeholder';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route exact path='/' element={<Welcome />} />
          <Route path='/start-survey' element={<StartSurvey />} />
          <Route path='/data' element={<Data />} />
          <Route path='/survey' element={<ActualSurvey />} />
          <Route path='/scientific-work' element={<ScientificWork />} />
          <Route path='/survey-consent' element={<SurveyConsent />} />
          <Route path='/show-item-details/:id' element={<ShowItemDetails />} />
          <Route path='/discussion' element={<Discussion />} />
          <Route path='/testbackend' element={<TestBackend />} />
          <Route path='/testsubmit' element={<TestSubmit />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/test-page" element={<TestPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;