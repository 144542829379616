import React from 'react';
import { Navbar, Nav, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../App.css';
import NavbarComponent from './NavbarComponent';

function Welcome() {
    return (
        <div className='green-bg text-center'>
            <NavbarComponent />
            <Container>
                <div className="Welcome">
                    <Card body className="welcome-card mb-4 mt-4">
                        <p>
                            Welcome to our website dedicated to the investigation of perceived danger levels associated with objects. Our primary goal is to measure and analyze how people perceive dangers from various objects, to highlight possible relationships between these perceptions, and to gain insight into the factors that influence these danger perceptions.
                        </p>
                        <p>
                            Our objective is to explore the reasons for differences in danger perceptions of objects used in different scenarios, especially used in a human-robot collaboration, and to find out why people judge certain objects to be more dangerous than others.
                        </p>
                    </Card>

                    <Card body>
                        <p>
                            You have the opportunity to take part in our survey. If you are not registered with Prolific, you do not need to enter a Prolific ID. <br /> Click <Link to="/start-survey">here</Link> to start the survey.
                        </p>
                        <p>
                            Also, we are always keen to share the latest results of our survey. Therefore, raw data in the form of recordings of data from surveys that have taken place can be viewed or downloaded and used. <br /> Click <Link to="/data">here</Link> to go to the data page.
                        </p>
                        <p>
                            If you have any feedback, suggestions, or proposals regarding our survey, please feel free to contact us. We are also happy to see our object set steadily growing and for you to contribute to it. <br /> Click <Link to="/discussion">here</Link> to go to the relevant discussion page.
                        </p>
                        <p>
                            If you are interested in our study results, we provide links to our papers. <br /> Click <Link to="/scientific-work">here</Link> to go to the Scientific Work page.
                        </p>
                    </Card>
                </div>
            </Container >
        </div >
    );
}

export default Welcome;