import React, { useState } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';

const ItemOthersUsingComponent = ({ workshopItem, onChange, onNextStep, onPrevStep, formData, othersStep, error }) => {


    const handleChangeItemOthersUsing = (event) => {
        const { name, value } = event.target;
        onChange(name, value, othersStep);
    };

    const ItemOthersUsingNextStep = () => {
        onNextStep();
    }

    const ItemOthersUsingPrevStep = () => {
        onPrevStep();
    }

    return (
        <Container>
            <div>
                <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Workshop Utensil Perception When Others Use the Item</h2>
                <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>Understanding Other Use</h3>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '600px' }}>
                        <img src={`${process.env.PUBLIC_URL}/workshopItems/${workshopItem.File}`} alt={workshopItem.Values} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                    <h4 style={{ margin: '20px 0 5px 0' }}> {/* Reduced bottom margin */}
                        {workshopItem.Values}
                    </h4>
                    {workshopItem["Also Known As"] && (
                        <p style={{ margin: '5px 0 0 0' }}> {/* Reduced top margin */}
                            Also Known As: {workshopItem["Also Known As"]}
                        </p>
                    )}
                    <br />
                </div>

                <div style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                    Now, envision <b>a person next to you</b> is using this item.<br />
                    Then answer the questions below.
                </div>
                {/* Question */}
                <Form.Group>
                    <Form.Label>I find the situation dangerous when someone next to me is using this item.</Form.Label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ paddingRight: "10px" }}>Strongly Disagree</span>
                        <Form.Control
                            type="range"
                            name="dangerPerception"
                            min="0"
                            max="100"
                            value={formData.itemOthersUsing[othersStep - 1]?.dangerPerception || ''}
                            onChange={handleChangeItemOthersUsing}
                        />
                        <span style={{ paddingLeft: "10px" }}>Strongly Agree</span>
                    </div>
                </Form.Group>
                <br />
                {error && <Alert variant="danger">{error}</Alert>}
                {/* The "next" and "previous" buttons */}
                <Button variant="secondary" onClick={ItemOthersUsingPrevStep}>Previous</Button>
                <Button variant="primary" onClick={ItemOthersUsingNextStep}>Next</Button>
                <br />
                <br />

            </div>
        </Container>
    );
};

export default ItemOthersUsingComponent;