import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';

function StartSurvey() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const initialProlificID = params.get('PROLIFIC_PID') || '';
    const initialStudyID = params.get('STUDY_ID') || '';
    const initialSessionID = params.get('SESSION_ID') || '';

    const [prolificID, setProlificID] = useState(initialProlificID);
    const [studyID, setStudyID] = useState(initialStudyID);
    const [sessionID, setSessionID] = useState(initialSessionID);

    const handleStartSurvey = () => {
        navigate('/survey-consent', { state: { PROLIFIC_PID: prolificID, STUDY_ID: studyID, SESSION_ID: sessionID } });
    }

    return (
        <div style={{ backgroundColor: 'white' }}>
            <div className="start-survey-container d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <Button onClick={handleStartSurvey} variant="primary" className="mb-3">Start survey</Button>

                    <div className="form-group mb-3">
                        <label htmlFor="prolificID">Prolific ID:</label>
                        <input
                            type="text"
                            id="prolificID"
                            className="form-control"
                            value={prolificID}
                            onChange={(e) => setProlificID(e.target.value)}
                            placeholder="Enter your Prolific ID"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StartSurvey;
