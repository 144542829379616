import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../App.css';
import NavbarComponent from './NavbarComponent';

function Placeholder() {
    return (
        <div className='green-bg'>
            <NavbarComponent />
            <div className="Welcome text-center">
                <h1>Danger Perception in the Context of Human-Robot Interaction: Workshot Items</h1>
                <p>
                    Welcome! Our survey is currently in progress. Results will be available soon.
                </p>
                <p>
                    Got lost? You can take our <Link to="/start-survey">Survey</Link> here.
                </p>
            </div>
        </div>
    );
}

export default Placeholder;
