import React, { useState } from 'react';
import axios from 'axios';

const payload = {
    "PROLIFIC_PID": "",
    "item1": "workshopItems/vise.JPG",
    "item2": "workshopItems/spiritlevel.JPG",
    "item3": "workshopItems/cclamp.JPG",
    "item4": "workshopItems/tapemeasure.JPG",
    "item5": "workshopItems/clawhammer.JPG",
    "item6": "workshopItems/spackleknife.JPG",
    "item7": "workshopItems/scalpel.JPG",
    "item8": "workshopItems/boxcutter.JPG",
    "item9": "workshopItems/shearcutterpliers.JPG",
    "item0": "workshopItems/ballpointpen.JPG",
    "itemName1": "Vise",
    "itemName2": "Spirit Level",
    "itemName3": "C-Clamp",
    "itemName4": "Tape Measure",
    "itemName5": "Claw Hammer",
    "itemName6": "Spackle Knife",
    "itemName7": "Scalpel",
    "itemName8": "Box Cutter",
    "itemName9": "Shear Cutter Pliers",
    "itemName0": "Ballpoint Pen",
    "item1ID": "64fdc43099d903e3dbe387a3",
    "item2ID": "64fdc43099d903e3dbe387a1",
    "item3ID": "64fdc43099d903e3dbe38784",
    "item4ID": "64fdc43099d903e3dbe387a2",
    "item5ID": "64fdc43099d903e3dbe38785",
    "item6ID": "64fdc43099d903e3dbe3879f",
    "item7ID": "64fdc43099d903e3dbe3879d",
    "item8ID": "64fdc43099d903e3dbe38783",
    "item9ID": "64fdc43099d903e3dbe3879e",
    "item0ID": "64fdc43099d903e3dbe38782",
    "AgeS": 23,
    "SexS": "male",
    "degree": "Some college but no degree",
    "occupation": "23",
    "SelfDescription": "no description",
    "country": "Bahamas",
    "workshop_expertise": 84,
    "household": 23,
    "workshop_frequency": 23,
    "workshop_alone": 23,
    "workshop_together": 23,
    "ATI01": "Completely Disagree",
    "ATI02": "Completely Disagree",
    "ATI03": "Completely Disagree",
    "ATI04": "Completely Disagree",
    "ATI05": "Completely Disagree",
    "ATI06": "Completely Disagree",
    "ATI07": "Completely Disagree",
    "ATI08": "Completely Disagree",
    "ATI09": "Completely Disagree",
    "attentionCheck0": 100,
    "attentionCheck1": 100,
    "attentionCheck2": 4,
    "general_feedback": "",
    "1_using_self_owning": "Yes",
    "1_using_self_familiar": 10,
    "1_using_self": getRandomNumber(10, 90),
    "2_using_self_owning": "Yes",
    "2_using_self_familiar": 16,
    "2_using_self": getRandomNumber(10, 90),
    "3_using_self_owning": "No",
    "3_using_self_familiar": 19,
    "3_using_self": getRandomNumber(10, 90),
    "4_using_self_owning": "No",
    "4_using_self_familiar": 21,
    "4_using_self": getRandomNumber(10, 90),
    "5_using_self_owning": "Yes",
    "5_using_self_familiar": 14,
    "5_using_self": getRandomNumber(10, 90),
    "6_using_self_owning": "No",
    "6_using_self_familiar": 12,
    "6_using_self": getRandomNumber(10, 90),
    "7_using_self_owning": "No",
    "7_using_self_familiar": 7,
    "7_using_self": getRandomNumber(10, 90),
    "8_using_self_owning": "Yes",
    "8_using_self_familiar": 9,
    "8_using_self": getRandomNumber(10, 90),
    "9_using_self_owning": "No",
    "9_using_self_familiar": 2,
    "9_using_self": getRandomNumber(10, 90),
    "10_using_self_owning": "Yes",
    "10_using_self_familiar": 11,
    "10_using_self": getRandomNumber(10, 90),
    "1_using_other": getRandomNumber(10, 90),
    "2_using_other": getRandomNumber(10, 90),
    "3_using_other": getRandomNumber(10, 90),
    "4_using_other": getRandomNumber(10, 90),
    "5_using_other": getRandomNumber(10, 90),
    "6_using_other": getRandomNumber(10, 90),
    "7_using_other": getRandomNumber(10, 90),
    "8_using_other": getRandomNumber(10, 90),
    "9_using_other": getRandomNumber(10, 90),
    "10_using_other": getRandomNumber(10, 90),
    "1_using_robot": getRandomNumber(10, 90),
    "2_using_robot": getRandomNumber(10, 90),
    "3_using_robot": getRandomNumber(10, 90),
    "4_using_robot": getRandomNumber(10, 90),
    "5_using_robot": getRandomNumber(10, 90),
    "6_using_robot": getRandomNumber(10, 90),
    "7_using_robot": getRandomNumber(10, 90),
    "8_using_robot": getRandomNumber(10, 90),
    "9_using_robot": getRandomNumber(10, 90),
    "10_using_robot": getRandomNumber(10, 90),
};

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


const TestSubmit = () => {
    const [responseMessage, setResponseMessage] = useState('');

    // Function to handle form submission
    const handleSubmit = async () => {
        try {
            // Make a POST request to the backend
            const response = await axios.post(`/api/submitSurvey`, payload);

            // Update the state with the response message from the backend
            setResponseMessage(response.data.msg || 'Success');
        } catch (error) {
            console.error('Error submitting data:', error);
            setResponseMessage('Error submitting data');
        }
    };

    return (
        <div>
            <h1>Test Submit</h1>
            <button onClick={handleSubmit}>Submit Test Data</button>
            <p>{responseMessage}</p>
        </div>
    );
};

export default TestSubmit;
