import React from 'react';

const TestPage = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="text-center">
                <div className='submit_button'>
                    <a className="btn btn-primary" role="button" >Submit</a>
                </div>
                <p className="text-muted mt-2">Press the button to submit and wait to be directed to the next page.</p>
            </div>
        </div>
    );
};

export default TestPage;
