import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function NavbarComponent() {
    return (
        <Navbar className="custom-navbar" expand="lg">
            <Navbar.Brand as={Link} to="/">HRI-Objects</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link as={Link} to="/data">Data</Nav.Link>
                    <Nav.Link as={Link} to="/start-survey">Survey</Nav.Link>
                    <Nav.Link as={Link} to="/scientific-work">Scientific Work</Nav.Link>
                    <Nav.Link as={Link} to="/discussion">Discussion</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavbarComponent;
