import React, { useState, useEffect } from 'react';
import axios from 'axios';

function WorkshopItemsComponent() {
  const [workshopItems, setWorkshopItems] = useState([]);
  const [shouldLoad, setShouldLoad] = useState(null);

  useEffect(() => {
    if (shouldLoad) {
      fetchWorkshopItems();
    }
  }, [shouldLoad]);

  const fetchWorkshopItems = async () => {
    try {
      const response = await axios.get(`/api/workshopItems`);
      setWorkshopItems(response.data);
    } catch (error) {
      console.error('Error fetching workshop items:', error);
    }
  };

  const fetchSurveyItems = async () => {
    try {
      const response = await axios.get(`/api/surveyItems`);
      setWorkshopItems(response.data);
    } catch (error) {
      console.error('Error fetching survey items:', error);
    }
  };

  return (
    <div>
      <h1>Workshop Items</h1>
      {/* Button to load all items */}
      <button onClick={() => setShouldLoad(Date.now())}>Load All Items</button>
      {/* Button to load survey items */}
      <button onClick={fetchSurveyItems}>Get Survey Items</button>
      <ul>
        {workshopItems.map(object => (
          <li key={object._id}>
            <strong>Name: </strong>{object.Values}<br />
            <img
              src={`workshopItems/${object.File}`}
              alt={object.Comment}
              style={{ maxWidth: '20%', height: 'auto' }}
            />
            <br />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WorkshopItemsComponent;
