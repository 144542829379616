import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import NavbarComponent from './NavbarComponent';
import { Navbar, Nav, Container } from 'react-bootstrap';


function Discussion() {
    return (
        <div className='green-bg text-center'>
            <NavbarComponent />
            <Container className='mt-4'>
                <p>
                    Click <Link to="https://hri-objects.leusmann.io/">here</Link> to be redirected to the page of the previous study of Leusmann et al.
                </p>
                <p>
                    Click <Link to="https://github.com/mimuc/hri-objects">here</Link> to access data via Github or to find contact Information.
                </p>
            </Container>
        </div>
    );
}
export default Discussion;