import React from 'react';
import Button from 'react-bootstrap/Button';

const ThankYouPage = () => {
    const redirectToProlific = () => {
        window.open('https://app.prolific.co/submissions/complete?cc=C1D5AMKT', '_blank');
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div>
                <p className="text-center">Thank you for participating!</p>
                <p className="text-center">Prolific Completion Code: <strong>C1D5AMKT</strong></p>
                <Button variant="primary" onClick={redirectToProlific}>Claim compensation on Prolific</Button>
            </div>
        </div>
    );
};

export default ThankYouPage;
