//restart the survey -> onChange method (prop) commented out -> first dom (step 5) will load -> changing the inputs and clicking next
//-> it will still give the error that there where no inputs made -> but rerendering that step 5 does work bc of these we have in the 
//component itself: 
//const [ownsItem, setOwnsItem] = useState(undefined);
//const [familiarity, setFamiliarity] = useState(undefined);
//const [dangerPerception, setDangerPerception] = useState(undefined); 
//and:
//value={familiarity} -> value of the inputs refer to these

//YOU CANNOT GIVE A FIXED VALUE (anywhere but radios) or it is not changeable (eg slider can not be slided)

//So I assigned it to a simple variable from the child component:
//let familiarity = undefined;
//let dangerPerception = undefined;
//and rerendering worked

import React, { useState } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';

const ItemSelfUsingComponent = ({ workshopItem, onChange, onNextStep, onPrevStep, formData, selfStep, error }) => {


    const handleChangeItemSelfUsing = (event) => {
        const { name, value } = event.target;
        onChange(name, value, selfStep);
    };

    const ItemSelfUsingNextStep = () => {
        onNextStep();
    }

    const ItemSelfUsingPreviousStep = () => {
        onPrevStep();
    }

    return (
        <Container>
            <div>
                <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Workshop Utensil Familiarity and Perception When Using the Item</h2>
                <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>Understanding Self Use</h3>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '600px' }}>
                        <img src={`${process.env.PUBLIC_URL}/workshopItems/${workshopItem.File}`} alt={workshopItem.Values} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                    <h4 style={{ margin: '20px 0 5px 0' }}>
                        {workshopItem.Values}
                    </h4>
                    {workshopItem["Also Known As"] && (
                        <p style={{ margin: '5px 0 0 0' }}>
                            Also Known As: {workshopItem["Also Known As"]}
                        </p>
                    )}
                    <br />
                </div>

                <div style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                    Now, envision <b>you</b> are using this item. Then answer the questions below.
                </div>
                {/* Questions */}
                <Form.Group>
                    <Form.Label>I own this item.</Form.Label>
                    <div>
                        <Form.Check
                            type="radio"
                            id="ownsItem-Yes"
                            name="ownsItem"
                            label="Yes"
                            value="Yes"
                            checked={formData.itemSelfUsing[selfStep - 1]?.ownsItem === 'Yes' || ''}
                            onChange={handleChangeItemSelfUsing}
                        />
                        <Form.Check
                            type="radio"
                            id="ownsItem-No"
                            name="ownsItem"
                            label="No"
                            value="No"
                            checked={formData.itemSelfUsing[selfStep - 1]?.ownsItem === 'No' || ''}
                            onChange={handleChangeItemSelfUsing}
                        />
                    </div>
                </Form.Group>
                <br />

                <Form.Group>
                    <Form.Label>I am very familiar with this item.</Form.Label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ paddingRight: "10px" }}>Strongly Disagree</span>
                        <Form.Control
                            type="range"
                            name="familiarity"
                            min="0"
                            max="100"
                            value={formData.itemSelfUsing[selfStep - 1]?.familiarity || ''}
                            onChange={handleChangeItemSelfUsing}
                        />
                        <span style={{ paddingLeft: "10px" }}>Strongly Agree</span>
                    </div>
                </Form.Group>
                <br />

                <Form.Group>
                    <Form.Label>I find the situation dangerous when I am using this item.</Form.Label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ paddingRight: "10px" }}>Strongly Disagree</span>
                        <Form.Control
                            type="range"
                            name="dangerPerception"
                            min="0"
                            max="100"
                            value={formData.itemSelfUsing[selfStep - 1]?.dangerPerception || ''}
                            onChange={handleChangeItemSelfUsing}
                        />
                        <span style={{ paddingLeft: "10px" }}>Strongly Agree</span>
                    </div>
                </Form.Group>
                <br />
                {error && <Alert variant="danger">{error}</Alert>}
                {/* The "next" and "previous" buttons */}
                <Button variant="secondary" onClick={ItemSelfUsingPreviousStep}>Previous</Button>
                <Button variant="primary" onClick={ItemSelfUsingNextStep}>Next</Button>
                <br />
                <br />


            </div>
        </Container>
    );
};

export default ItemSelfUsingComponent;