import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';


function ShowItemDetails() {
    return (

        <div className='ShowItemDetails'>
            <div className='start_survey_button'>
                <Link
                    to='/survey-consent'
                    className='startSurveyLink'>
                    Start Survey
                </Link>
            </div>
        </div>
    );
}
export default ShowItemDetails;