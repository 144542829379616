import React, { useState } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';

const AttentionCheck = ({ workshopItem, attentionCheckIndex, attentionCheckDirection, onChange, onNextStep, onPrevStep, formData, selfStep, error }) => {
    const handleChangeAttentionCheck = (event) => {
        const { name, value } = event.target;
        onChange(value, attentionCheckIndex);
    };

    const ItemOthersUsingNextStep = () => {
        onNextStep();
    }

    const ItemOthersUsingPrevStep = () => {
        onPrevStep();
    }

    return (
        <Container>
            <div>
                <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>Workshop utensil perception</h2>
                <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>General Understanding </h3>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ width: '600px' }}>
                        <img src={`${process.env.PUBLIC_URL}/workshopItems/${workshopItem.File}`} alt={workshopItem.Values} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                    </div>
                    <h4 style={{ margin: '20px 0' }}>
                        {workshopItem.Values}
                        {workshopItem["Also Known As"] && (
                            <span>
                                , {workshopItem["Also Known As"]}
                            </span>
                        )}
                    </h4>
                </div>

                <div style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                    Consider the item displayed above.<br />
                    Then respond to the inquiry below.
                </div>

                <Form.Group>
                    <Form.Label>
                        {attentionCheckDirection === 0 ? "Move the slider all the way to the right." : "Move the slider all the way to the left."}
                    </Form.Label>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ paddingRight: "10px" }}>Strongly Disagree</span>
                        <Form.Control
                            type="range"
                            name="dangerPerception"
                            min="0"
                            max="100"
                            value={formData.attentionChecks ? formData.attentionChecks[attentionCheckIndex] : ''}
                            onChange={handleChangeAttentionCheck}
                        />
                        <span style={{ paddingLeft: "10px" }}>Strongly Agree</span>
                    </div>
                </Form.Group>
                <br />
                {error && <Alert variant="danger">{error}</Alert>}
                {/* The "next" and "previous" buttons */}
                <Button variant="secondary" onClick={ItemOthersUsingPrevStep}>Previous</Button>
                <Button variant="primary" onClick={ItemOthersUsingNextStep}>Next</Button>
                <br />
                <br />

            </div>
        </Container>
    );
}

export default AttentionCheck;