const affinityQuestions = [
    'I like to occupy myself in greater detail with technical systems.',
    'I like testing the functions of new technical systems.',
    'I predominantly deal with technical systems because I have to.',
    'When I have a new technical system in front of me, I try it out intensively.',
    'I enjoy spending time becoming acquainted with a new technical system.',
    'It is enough for me that a technical system works; I don’t care how or why.',
    'I try to understand how a technical system exactly works.',
    'It is enough for me to know the basic functions of a technical system.',
    'I try to make full use of the capabilities of a technical system.',
];

export default affinityQuestions;